<script setup lang="ts">
import { computed, watch, ref, useSlots, useAttrs } from 'vue';
import { QSelect } from 'quasar';
import { VirtualScrollDetails } from '../../types/virtual-scroll';
import { SzSelectDenseEmits, SzSelectDenseProps } from './types';

const props = defineProps(SzSelectDenseProps);

const emits = defineEmits(SzSelectDenseEmits);

const slots = useSlots();

const attrs = useAttrs();

const qSelectRef = ref<QSelect>();

const customSlots = computed(() =>
  ['after-options'].concat(Object.keys(slots))
);

const loading = ref(props.loading);

const cyName = computed(() => {
  if (props.name) {
    return `form-${props.name}`;
  }
  return '';
});

const normalizedBind = computed(() => ({
  ...attrs,
  ...props,
  'data-cy': cyName.value
}));

const isLoading = computed(() => loading.value || props.loading);

const onVirtualScroll = async (evt: VirtualScrollDetails) => {
  emits('virtual-scroll', evt);

  if (
    !isLoading.value &&
    typeof props.onRefresh === 'function' &&
    evt.index === evt.to
  ) {
    loading.value = true;

    await props.onRefresh(evt);

    loading.value = false;
  }
};

function closeOptionsList() {
  if (!qSelectRef.value) {
    return;
  }

  try {
    qSelectRef.value.hidePopup();
  } catch (error) {
    console.error(error);
  }
}

watch(
  () => props.options.length,
  () => {
    qSelectRef.value?.refresh();
  }
);

defineExpose({
  hidePopup: closeOptionsList
});
</script>

<template>
  <span>
    <q-select
      ref="qSelectRef"
      class="sz-select"
      v-bind="normalizedBind"
      :data-cy="cyName"
      v-on="$listeners"
      @virtual-scroll="onVirtualScroll"
    >
      <slot></slot>

      <template v-for="slotName in customSlots" v-slot:[slotName]="scope">
        <div
          v-if="slotName === 'after-options' && isLoading"
          class="col q-gutter-y-md q-px-md q-py-sm cursor-wait"
          :key="slotName"
        >
          <q-skeleton v-for="skeleton in 3" :key="skeleton" type="text" />
        </div>

        <slot
          :name="slotName"
          v-bind="{ props: scope, closeOptionsList, ...scope }"
        ></slot>
      </template>
    </q-select>
  </span>
</template>
