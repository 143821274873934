<script setup lang="ts">
import { computed, ref } from 'vue';
import { InputEvent, SzInputOtpProps } from './types';
import { INPUTS_DATA } from './utils';
import { QInput } from 'quasar';
import { ValidationProps } from '../../types';

const props = defineProps(SzInputOtpProps);

const emit = defineEmits(['input', 'focus']);

const inputRef = ref([] as QInput[]);

const syncedValue = computed({
  get() {
    return props.value;
  },
  set(newValue) {
    emit('input', newValue);
  }
});

const splittedValue = computed({
  get() {
    const __splittedValue = props.value.split('');

    return __splittedValue.concat(
      Array.from({ length: props.length - __splittedValue.length }, () => '')
    );
  },
  set(newValue) {
    syncedValue.value = newValue.join('');
  }
});

const inputsData = computed(() =>
  props.intergerOnly ? INPUTS_DATA.interger : INPUTS_DATA.alphanumeric
);

const qFieldBind = computed(() => {
  const targets = Object.keys({ ...ValidationProps });

  const obj: any = {};

  for (const target of targets) {
    obj[target] = props[target];
  }

  return {
    ...obj,
    noErrorIcon: true,
    borderless: true
  };
});

const bind = computed(() => {
  const targets = ['dense', 'outlined', 'disable', 'color', 'error'] as const;

  const obj: {
    type?: string;
    mask: string;
  } = {
    mask: ''
  };

  for (const target of targets) {
    obj[target] = props[target];
  }

  if (props.mask) {
    obj.type = 'password';
  }

  return {
    ...obj,
    mask: inputsData.value.mask,
    hideBottomSpace: true,
    noErrorIcon: true
  };
});

function focusPrevOrNext({ inputIndex, event }: InputEvent<string>) {
  let index = !!event ? inputIndex + 1 : inputIndex - 1;

  onFocusInput({
    inputIndex: index
  });
}

function onFocusInput({ inputIndex }: InputEvent<FocusEvent>) {
  inputRef.value?.[inputIndex]?.resetValidation();
  inputRef.value?.[inputIndex]?.focus();
}

function onPasteInput({ event, inputIndex }: InputEvent<ClipboardEvent>) {
  if (!event) {
    return;
  }

  const pasted = event.clipboardData?.getData('text') || '';

  if (inputsData.value.regex.test(pasted)) {
    const splittedClipboard = pasted.trim().split('');

    const arr = splittedValue.value.slice();

    arr.forEach((_, index) => {
      if (index >= inputIndex) {
        const shifftedValue = splittedClipboard.shift();
        arr[index] = shifftedValue || '';
      }
    });

    syncedValue.value = arr.map(item => item || ' ').join('');

    const focusIndex = inputIndex + pasted.trim().split('').length - 1;

    onFocusInput({
      inputIndex: focusIndex > props.length - 1 ? props.length - 1 : focusIndex
    });
  }
}

function onKeyupInput({ event, inputIndex }: InputEvent<KeyboardEvent>) {
  if (!event) {
    return;
  }

  if (!Number.isNaN(Number(event.key))) {
    onInput({ event: event.key, inputIndex });
    return;
  }

  if (event.key === 'Backspace') {
    setValueAndEmit({ inputIndex });
    return;
  }

  if (event.key === 'ArrowRight') {
    focusPrevOrNext({ event: 'ArrowRight', inputIndex });
    return;
  }

  if (event.key === 'ArrowLeft') {
    focusPrevOrNext({ inputIndex });
    return;
  }
}

function onInput({ event, inputIndex }: InputEvent<string>) {
  if (event) {
    setValueAndEmit({ value: event, inputIndex });
  }
}

function setValueAndEmit({
  value,
  inputIndex
}: {
  value?: string;
  inputIndex: number;
}) {
  const arr = splittedValue.value.slice();

  arr[inputIndex] = value || '';

  syncedValue.value = arr.map(item => item || ' ').join('');

  focusPrevOrNext({ event: value, inputIndex });
}

function focus() {
  onFocusInput({ inputIndex: 0 });
}

function onClick() {
  if (!props.disable) {
    emit('focus');
  }
}

defineExpose({
  focus,
  inputRef
});
</script>

<template>
  <q-field v-model="syncedValue" class="sz-input-otp" v-bind="qFieldBind">
    <!-- fields -->
    <template #control>
      <div class="sz-input-otp__fields">
        <q-input
          ref="inputRef"
          class="sz-input-otp__input"
          v-for="(input, inputIndex) in props.length"
          :key="input"
          :value="splittedValue[inputIndex]"
          v-bind="bind"
          @click.native="onClick"
          @focus.prevent="onFocusInput({ inputIndex })"
          @paste.prevent="onPasteInput({ event: $event, inputIndex })"
          @keyup.prevent="onKeyupInput({ event: $event, inputIndex })"
        />
      </div>
    </template>
  </q-field>
</template>

<style lang="scss">
.sz-input-otp {
  max-width: 15rem;

  .q-field {
    &__control {
      padding: 0 5px;
      font-size: 30px;
    }

    &__control,
    &__native {
      padding: 0;
      min-height: 45px;
    }
  }

  &__fields {
    display: flex;
    gap: 1rem;

    input {
      color: var(--custom-color-font-primary);
      text-align: center;
      -moz-appearance: textfield;
      appearance: textfield;

      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
}
</style>
