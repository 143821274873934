<script setup lang="ts">
import { computed, useAttrs, useSlots } from 'vue';
import useColor from '../../composables/useColor';
import { SzBtnProps } from './types';

const props = defineProps(SzBtnProps);

const attrs = useAttrs();

const slots = useSlots();

const bind = computed(() => {
  const { bgClasses, complementTextClasses } = useColor('button');

  const color =
    props.color === 'primary'
      ? { class: ['sz-btn', bgClasses.value, complementTextClasses.value] }
      : { color: props.color };

  return {
    ...attrs,
    ...props,
    ...color
  };
});

const slotsKeys = computed(() => {
  const keys = Object.keys(slots);

  if (props.tooltip && !keys.includes('default')) {
    keys.push('default');
  }

  return keys;
});
</script>

<template>
  <q-btn v-bind="bind" v-on="$listeners">
    <template v-for="slotName in slotsKeys" v-slot:[slotName]>
      <slot :name="slotName"></slot>

      <q-tooltip v-if="props.tooltip" :key="slotName">{{ tooltip }}</q-tooltip>
    </template>
  </q-btn>
</template>
